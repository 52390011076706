import React from 'react';
import {ThemeProvider} from 'styled-components';

import themes from '../commonStyles/themes';
import { MainContainer, ThemeContainer } from '../commonStyles/Containers';

import AllFixedElements from '../components/AllFixedElements';

import SEO from '../components/seo';

import SectionInfoCaseStudy from '../componentsCaseStudy/SectionInfoCaseStudy';
import SectionImageCaseStudy from '../componentsCaseStudy/SectionImageCaseStudy';
import SectionDescCaseStudy  from '../componentsCaseStudy/SectionDescCaseStudy';
import SectionPaletteCaseStudy from '../componentsCaseStudy/SectionPaletteCaseStudy';
import SectionImagesTiles from '../componentsCaseStudy/SectionImagesTiles';
import SectionLastCaseStudy from '../componentsCaseStudy/SectionLastCaseStudy'
import VideoCaseStudy from '../componentsCaseStudy/VideoCaseStudy'

import SectionFooter from '../components/SectionFooter';
import GlobalStyle from '../components/layoutCss';

import AcaraContent from '../data/AcaraContent';

class AcaraCaseStudy extends React.PureComponent {


  render() {
    return (
      <>
        <GlobalStyle />
        <ThemeProvider theme={themes.themeMain}>
        <ThemeContainer>
          <>
            <SEO
              title='Acara'
              path={this.props.path}
            />
            <AllFixedElements
              isCaseStudy={true}
            />
            <MainContainer>
                <SectionInfoCaseStudy
                  topdesc1={AcaraContent.topdesc1}
                  topdesc2={AcaraContent.topdesc2}
                  projectName={AcaraContent.projectName}
                  projectCategories1={AcaraContent.projectCategories1}
                  projectCategories2={AcaraContent.projectCategories2}
                  projectDeliverables1={AcaraContent.projectDeliverables1}
                  projectDeliverables2 ={AcaraContent.projectDeliverables2}
                />
                <VideoCaseStudy videoSrc={AcaraContent.HeadVideo}/>

                {/*----------------------------User experience and interface----------------------------*/}

                <SectionDescCaseStudy
                  descCounter ={'01'}
                  descHeader={AcaraContent.descHeader1}
                  descParagraph={AcaraContent.descParagraph1}
                />
                <SectionImageCaseStudy bgImg={AcaraContent.ImageWireframes} />
                <SectionImageCaseStudy bgImg={AcaraContent.ImageWireframes2} />
                <SectionImageCaseStudy bgImg={AcaraContent.ImageUI} />
                <SectionImagesTiles myArray={AcaraContent.GalleryUI1}/>
                <SectionImageCaseStudy bgImg={AcaraContent.ImageUI2} />
                <SectionImageCaseStudy bgImg={AcaraContent.ImageUI3} />
                <SectionImagesTiles myArray={AcaraContent.GalleryUI2}/>

                {/*----------------------------Visual identity----------------------------*/}

                <SectionDescCaseStudy
                  descCounter ={'02'}
                  descHeader={AcaraContent.descHeader2}
                  descParagraph={AcaraContent.descParagraph2}
                />
                <SectionPaletteCaseStudy myColorPalette={AcaraContent.colorPalette}/>
                <SectionImageCaseStudy bgImg={AcaraContent.ImageStyle} />
                <SectionImagesTiles myArray={AcaraContent.GalleryIcons} />
                <SectionImagesTiles myArray={AcaraContent.GalleryLogo}/>
                <SectionDescCaseStudy
                  descCounter ={'03'}
                  descHeader={AcaraContent.descHeader3}
                  descParagraph={AcaraContent.descParagraph3}
                />
                <SectionImageCaseStudy bgImg={AcaraContent.ImageBranding} />
                <SectionImageCaseStudy bgImg={AcaraContent.ImageBranding2} />
                <SectionImagesTiles myArray={AcaraContent.GalleryBranding}/>

                  {/*----------------------------Website----------------------------*/}

                <SectionDescCaseStudy
                  descCounter ={'04'}
                  descHeader={AcaraContent.descHeader4}
                  descParagraph={AcaraContent.descParagraph4}
                />
                <SectionImagesTiles myArray={AcaraContent.GalleryLanding}/>
                <SectionImageCaseStudy bgImg={AcaraContent.ImageLanding} />
                <SectionLastCaseStudy />
                <SectionFooter />
            </MainContainer>
          </>
          </ThemeContainer>
        </ThemeProvider>
      </>
    );
  }
}

export default AcaraCaseStudy;
