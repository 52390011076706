import wireframes from '../images/projects/acara/wireframes.png';
import wireframes2 from '../images/projects/acara/wireframes2.png';
import dashboard from '../images/projects/acara/acara_dashboard.png';
import dashboard2 from '../images/projects/acara/acara_dashboard2.png';
import dashboard3 from '../images/projects/acara/acara_dashboard2.png';
import editor from '../images/projects/acara/acara_editor_editelement.png';
import editor2 from '../images/projects/acara/acara_editor_gallery.png';
import mobile1 from '../images/projects/acara/mobile1.png';
import mobile2 from '../images/projects/acara/mobile2.png';
import logoFinal from '../images/projects/acara/branding/logo.png';
import logoFinal2 from '../images/projects/acara/branding/logotype.png';
import bcards from '../images/projects/acara/branding/bcards.png';
import bcards2 from '../images/projects/acara/branding/bcards2.jpg';
import branding2 from '../images/projects/acara/branding/acara_7.jpg';
import branding3 from '../images/projects/acara/branding/acara_8.jpg';
import landingFront from '../images/projects/acara/landing_front.png';

import typo from '../images/projects/acara/branding/typo.png';
import icons1 from '../images/projects/acara/branding/icon1.png';
import icons2 from '../images/projects/acara/branding/icon2.png';
import uiKit from '../images/projects/acara/uikit.png';

import AcaraHeadVideo from '../images/projects/acara/videos/landing_header.mp4';
import AcaraCreateVideo from '../images/projects/acara/videos/create_small_blue.mp4';
import AcaraVisualizeVideo from '../images/projects/acara/videos/visualize_small_blue.mp4';
import AcaraLogoVideo from '../images/projects/acara/videos/logo_animh264.mp4';

const AcaraPalette = [
  { id: 1, colorCode: '#5969e0', colorName: 'Royal blue', isDark: true },
  { id: 2, colorCode: '#455eee', colorName: 'Acara Blue', isDark: true },
  { id: 3, colorCode: '#d2d8f8', colorName: 'Periwinkle' },
  { id: 4, colorCode: '#a5adc6', colorName: 'Wild blue yonder' },
  { id: 5, colorCode: '#3c4049', colorName: 'Arsenic', isDark: true },
  { id: 6, colorCode: '#17253a', colorName: 'Yankees blue', isDark: true },
  { id: 7, colorCode: '#f5f6fa', colorName: 'Light grey' },
  { id: 8, colorCode: '#ffffff', colorName: 'White' },
];

export const GalleryDesktopUIAcara1 = [
  { id: 1, src: dashboard },
  { id: 2, src: dashboard2 }
];

export const GalleryMobileUIAcara = [
  { id: 1, src: mobile1 },
  { id: 2, src: mobile2 }
];

export const GalleryLogoAcara = [
  { id: 1, isVideo: true, videoSrc: AcaraLogoVideo },
  { id: 2, src: logoFinal },
  { id: 3, src: logoFinal2 }
];

export const GalleryBrandingAcara = [
  { id: 1, src: branding2 },
  { id: 2, src: branding3 }
];

export const GalleryIconsAcara = [
  { id: 1, src: icons1 },
  { id: 2, src: icons2 }
];

export const GalleryLandingAcara = [
  { id: 1, isVideo: true, videoSrc: AcaraCreateVideo },
  { id: 1, isVideo: true, videoSrc: AcaraVisualizeVideo }
];


export default {
  colorPalette: AcaraPalette,
  HeadVideo: AcaraHeadVideo,
  projectName: 'Acara',
  projectCategories1:'Visual identity',
  projectCategories2:'UI/UX',
  projectDeliverables1:'Concept, Information Architecture',
  projectDeliverables2:'Design & Development',
  topdesc1: 'Acara is a powerful event marketing platform, enabling the users to drive business performance through in-person events. ',
  topdesc2: 'It helps the customers to create and manage their event pages and marketing assets while maintaining brand consistency.',

  descHeader1:'The task',
  descParagraph1:'Acara needed a complete, modern visual identity build from scratch and a complex event marketing tool, offering different features to the customers. We have created simple and lightweight user interfaces for Acara app, clean, modern visual identity and a website design.',

  ImageWireframes: wireframes,
  ImageWireframes2: wireframes2,
  ImageUI: dashboard3,
  GalleryUI1: GalleryDesktopUIAcara1,
  ImageUI2: editor,
  ImageUI3: editor2,
  GalleryUI2: GalleryMobileUIAcara,

  descHeader2:'Style guide',
  descParagraph2:'There’s a strong ongoing trend for minimal, almost ascetic designs. But Acara is complex, enterprise product with many features. And it’s still growing. That`s why we`ve concentrated on building a consistent design system. With such variety of content shown on the screen, the color palette has to be tight, yet strong and clean. It can’t interfere with users’ custom color schemes and images in the editor. Intensive Acara Blue is used to emphasize important buttons, texts and icons.To help the users to concentrate on the information and their own designs, the interface is very clean and light.',
  ImageStyle: typo,
  GalleryIcons: GalleryIconsAcara,
  ImageStyle3: uiKit,
  descHeader3:'',
  descParagraph3:'We were also responsible for designing brand identity elements, such as business cards and envelopes.',
  GalleryLogo: GalleryLogoAcara,
  ImageBranding: bcards,
  ImageBranding2: bcards2,
  GalleryBranding: GalleryBrandingAcara,

  descHeader4:'The website',
  descParagraph4:'The website’s simple and lightweight visual style is a perfect, neutral environment, allowing to present the product and concentrate on it’s features. Animated micro-interactions and non-intrusive transitions are paired with Acara’s color palette and neat typography.',
  ImageLanding: landingFront,
  GalleryLanding: GalleryLandingAcara,
};
